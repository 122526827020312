import request from './ajax';
import qs from 'qs';

export const getIndex = (data) =>
    request({
        method: 'get',
        url: '/uc/index',
        params: data
    });

// 导航
export const getNav = (data) =>
    request({
        method: 'get',
        url: '/uc/nav',
        params: data
    });

// 用户地址
export const getUserAddress = () =>
    request({
        method: 'post',
        url: '/uc/address'
    });

// 添加用户地址
export const createUserAddress = (data) =>
    request({
        method: 'post',
        url: '/uc/address/create',
        params: data
    });

// 修改用户地址
export const updateUserAddress = (data) =>
    request({
        method: 'post',
        url: '/uc/address/update',
        params: data
    });

export const deleteUserAddress = (data) =>
    request({
        method: 'post',
        url: '/uc/address/delete',
        params: data
    });

export const getUserInfo = (data) =>
    request({
        method: 'get',
        url: '/uc/info',
        params: data
    });

export const getUserAuth = (data) =>
    request({
        method: 'get',
        url: '/auth/index',
        params: data
    });

export const createcompanyAuth = (data) =>
    request({
        method: 'post',
        url: '/auth/company',
        data: qs.stringify(data)
    });

export const createpersonAuth = (data) =>
    request({
        method: 'post',
        url: '/auth/realname',
        data: qs.stringify(data)
    });

export const getUserProfile = (data) =>
    request({
        method: 'get',
        url: '/uc/profile',
        data: qs.stringify(data)
    });

export const getUserProfileKey = (data) =>
    request({
        method: 'get',
        url: '/uc/profile/key',
        params: data
    });

export const saveProfile = (data) =>
    request({
        method: 'post',
        url: '/uc/profile/save',
        data: qs.stringify(data)
    });

/**
 * 修改公开资料等级
 * @param {*} data
 * @returns
 */
export const changeUserOpenLevel = (data) =>
    request({
        method: 'post',
        url: '/uc/profile/changeopenlevel',
        data: qs.stringify(data)
    });

/**
 * 修改工作经历
 * @param {*} data
 * @param {*}
 * @returns
 */
export const saveProfessionals = (data) =>
    request({
        method: 'post',
        url: '/uc/professional/save',
        data: qs.stringify(data)
    });

/**
 * 修改学习经历
 * @param {*} data
 * @param {*}
 * @returns
 */
export const saveProeducation = (data) =>
    request({
        method: 'post',
        url: '/uc/education/save',
        data: qs.stringify(data)
    });

/**
 * 修改荣誉资料
 * @param {*} data
 * @param {*}
 * @returns
 */
export const saveHonor = (data) =>
    request({
        method: 'post',
        url: '/uc/qualification/save',
        data: qs.stringify(data)
    });

export const deleteProfessionals = (data) =>
    request({
        method: 'post',
        url: '/uc/professional/delete',
        data: qs.stringify(data)
    });

export const deleteProeducation = (data) =>
    request({
        method: 'post',
        url: '/uc/education/delete',
        data: qs.stringify(data)
    });

export const deleteHonor = (data) =>
    request({
        method: 'post',
        url: '/uc/honor/delete',
        data: qs.stringify(data)
    });

export const bindEmail = (data) =>
    request({
        method: 'post',
        url: '/uc/honor/delete',
        data: qs.stringify(data)
    });

/**
 * 获取用户钱包
 * @returns {*}
 */
export const getWallet = (data) =>
    request({
        method: 'get',
        url: '/uc/wallet',
        params: data
    });

/**
 * 获取用户钱包明细
 * @param data
 * @returns {*}
 */
export const getWalletDetails = (data) =>
    request({
        method: 'get',
        url: '/uc/wallet/details',
        params: data
    });

// 站内信/通知
export const getNotifys = (data) =>
    request({
        method: 'get',
        url: '/uc/notice',
        params: data
    });

export const readNotify = (data) =>
    request({
        method: 'post',
        url: '/uc/notice/read',
        data: qs.stringify(data)
    });

export const notifyInfo = (data) =>
    request({
        method: 'get',
        url: '/uc/notice/info',
        params: data
    });

/**
 * 在线情况统计
 * @param data
 * @returns {*}
 */
export const online = (data) =>
    request({
        method: 'get',
        url: '/uc/online',
        params: data
    });

// 用户中心评价列表
export const getEvaluate = (data) =>
    request({
        method: 'get',
        url: '/uc/evaluate/list',
        params: data
    });

export const toEvaluate = (data) =>
    request({
        method: 'post',
        url: '/uc/evaluate/reply',
        data: qs.stringify(data)
    });

export const toRecharge = (data) =>
    request({
        method: 'post',
        url: '/uc/wallet/recharge',
        data: qs.stringify(data)
    });

export const queryRechargeStatus = (data) =>
    request({
        method: 'get',
        url: '/uc/wallet/queryrecharge',
        params: data
    });

export const toRechargeCoin = (data) =>
    request({
        method: 'post',
        url: '/uc/coin/recharge',
        data: qs.stringify(data)
    });

export const queryRechargeCoinStatus = (data) =>
    request({
        method: 'get',
        url: '/uc/coin/queryrecharge',
        params: data
    });

export const getMyCollect = (data) =>
    request({
        method: 'get',
        url: '/uc/collect',
        params: data
    });

// 添加用户银行卡
export const createUserBank = (data) =>
    request({
        method: 'post',
        url: '/uc/userbank/create',
        data: qs.stringify(data)
    });

// 删除用户银行卡
export const deleteUserBank = (data) =>
    request({
        method: 'get',
        url: '/uc/userbank/change',
        params: data
    });

// 切换用户银行卡
export const changeUserBank = (data) =>
    request({
        method: 'get',
        url: '/uc/userbank/change',
        params: data
    });

// 用户银行卡列表
export const getUserBankList = (data) =>
    request({
        method: 'get',
        url: '/uc/userbank/list',
        params: data
    });


export const getWechatQrCode = (data) =>
    request({
        method: 'get',
        url: '/wechat/bankqrcode',
        params: data
    });

export const getWechatQrCodeQuery = (data) =>
    request({
        method: 'get',
        url: '/wechat/bankqrcode/query',
        params: data
    });

export const userRechargeInfo = (data) =>
    request({
        method: 'get',
        url: '/uc/wallet/rechargeinfo',
        params: data
    });

export const getAgentInfo = (data) =>
    request({
        method: 'get',
        url: '/ald/getagentbytask',
        params: data
    });
