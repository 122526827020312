<template>
  <div class="log">
    <div class="login">
      <div class="login-width">
        <div class="login-center-width">
          <div class="login-center-width-img">
            <Isvg icon-class="logo-text" class-name="item-logo"/>
          </div>
          <div class="bindingPhone-center-head-portrait">
            <div><img :src="userInfo.avatar" alt=""/></div>
            <p>{{ userInfo.name || '用户您好' }}</p>
          </div>

          <div v-if="disabledToDo">
            <div class="center">
              <ValidationProvider rules="required|mobile" name="手机账号" v-slot="{ errors }">
                <input
                    class="input-400 login-input-heightbot login-input-block"
                    type="number"
                    minlength="11"
                    maxlength="11"
                    placeholder="请输入手机号码"
                    v-model.trim.number="account"
                />
                <p>{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="login-duanxi-input2">
              <ValidationProvider rules="required" name="验证码" v-slot="{ errors }" class="input-250" mode="passive">
                <div class="login-duanxi-yanzheng">
                  <input
                      v-model.trim="code"
                      maxlength="4"
                      minlength="4"
                      type="number"
                      placeholder="请输入验证码"
                      class="input-250 login-input-heightbot login-input-block"
                  />
                  <button :class="'button-blue-150' + btnStyle" @click="getSendCode(this)" ref="buttonNo">
                    {{ codeContent }}
                  </button>
                </div>
                <p>{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="center-button">
              <el-button class="button-blue-400" :loading="bindingLoding" @click="doBind">绑定</el-button>
            </div>
            <p class="register-goLogin" @click="doNotBind">暂时绑定，<a>跳过</a></p>
          </div>
          <div v-else>
            <div class="center">
              <div class="center-button">
                <input
                    class="input-400 login-input-heightbot login-input-block"
                    minlength="11"
                    maxlength="11"
                    placeholder="请输入手机号码"
                    disabled
                    v-model.trim.number="mobile"
                />
                <el-button type="info" style="width: 300px;margin-top: 20px;margin-bottom: 20px"
                           :loading="bindingLoding">已有绑定
                </el-button>
                <p class="register-goLogin"></p>
                <div class="mb-40"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <p class="register-beihao">
        © 2022 gogowork.cn All Rights Reserved 京IPC备：2021019219号-1
      </p>
    </div>
  </div>
</template>

<script>
import {requestWechatBind, requestSendCode} from '@/api/index';
import {getUserInfo} from "@/api/user"

export default {
  name: "bindingPhone",
  data() {
    return {
      userInfo: {},
      btnStyle: '',
      canClick: true,
      codeContent: '发送验证码',
      bindingLoding: false,
      account: '',
      mobile: '',
      wait: 60,
      code: '',
      disabledToDo: true
    }
  },
  mounted() {
    this.loadUserInfo()
  },
  methods: {
    doBind() {
      this.bindingLoding = true
      requestWechatBind({mobile: this.account, code: this.code}).then(res => {
        if (res.statusCode == 200) {
          this.$notify({
            type: 'success',
            message: '您已经成功绑定，正在为您跳转...'
          });
          // 标记且填充数据，只填充用户相关的
          this.$store.dispatch('useUserStore/setLoginStatusInfo', res.data)

          this.$redirectUrl.jumpToFrom('/home/page')
        } else {
          this.$notify({
            message: res.message,
            type: 'error'
          });
        }
        this.bindingLoding = false;
      }).catch(res => {
        this.bindingLoding = false;
      })
    },
    getSendCode() {
      if (!this.canClick) {
        return;
      }
      // 单独验证手机号
      this.$validate(this.account, 'required|mobile', {
        name: '手机号'
      }).then(result => {
        if (result.valid) {
          this.canClick = false;
          // 开始倒计时
          this.$refs.buttonNo.style = 'cursor: not-allowed';
          this.btnStyle = ' disabled';
          this.codeContent = this.wait + '秒';
          let clock = window.setInterval(() => {
            this.wait--;
            this.codeContent = this.wait + '秒';
            if (this.wait < 0) {
              //当倒计时小于0时清除定时器
              window.clearInterval(clock);
              this.codeContent = '重新发送验证码';
              this.wait = 60;
              this.canClick = true;
              this.btnStyle = '';
              this.$refs.buttonNo.style = 'cursor: pointer';
            }
          }, 1000);
          this.toSendCode();
        } else {
          this.$notify({
            message: '手机号格式错误',
            type: 'error'
          });
        }
      });
    },
    async toSendCode() {
      let res = await requestSendCode({account: this.account, code_type: 2});
      if (res.statusCode == 200) {
        this.$notify({
          message: res.data,
          type: 'success'
        });
      } else {
        this.$notify({
          message: res.message,
          type: 'warn'
        });
      }
    },
    async loadUserInfo() {
      const res = await getUserInfo()
      if (res.statusCode == 200) {
        if(res.data.has_login == 1 ) {// 非匿名
          this.userInfo = res.data
          this.mobile = res.data.mobile
          this.disabledToDo = false
        }else{
          // 匿名
          this.userInfo = {
            avatar: 'https://static.gogowork.cn/h/000/00/00/24/1_200X200.jpg',
            name: '您还不是正式用户，请先绑定手机号码'
          }
        }
      }
    },
    doNotBind() { //暂不绑定，跳转到上一个页面，若无，则跳转到用户首页
      let url = this.$route.query.redirect_url || '/home/page';
      this.$router.push(url);
    }
  }
};
</script>

<style lang="less" scoped>
.item-logo {
  width: 8em;
  height: 3em;
  margin-top: 20px;
}

.login {
  padding-top: 60px;
}

.login-duanxi-input1 {
  width: 300px;
  margin-bottom: 20px;

  p {
    font-size: 10px;
    color: #e4007f;
    font-weight: 400;
    margin-left: 10px;
  }
}

.login-duanxi-input2 {
  width: 300px;
  margin-bottom: 20px;

  .login-duanxi-yanzheng {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-size: 10px;
    color: #e4007f;
    font-weight: 400;
  }
}


.bindingPhone-center-head-portrait {
  margin-top: 30px;

  div {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid #efefef;
  }

  img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border-radius: 50%;
  }

  p {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #575757;
    margin-top: 20px;
    margin-bottom: 22px;
  }
}

.center {
  width: 300px;
  margin-top: 20px;
  margin-bottom: 14px;

  p {
    margin-left: 10px;
    color: #eb409f;
    font-size: 10px;
    font-weight: 400;
  }
}

.center2 {
  display: flex;
}

.center-button {
  margin-bottom: 40px;
}

.bin-zanshi {
  margin: 0 auto;
  width: 130px;
  height: 19px;
  font-size: 18px;
  color: #575757;

  span {
    color: #e4007f;
  }
}

.button-blue-400 {
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
</style>
